@import '~antd/dist/antd.css';
.logo {
  height: 51px;
  background: #141A29;
  text-align: center;
  padding: 15px 20px;
  border-bottom: 1px solid #171D22;
}
.trigger.menu-uncollapse{
  float:right;
}
.ant-layout-sider{
  background: #25303A;
}
.ant-table-tbody tr{
  background-color: white;
}
.logout-container{
  position: absolute !important;
  bottom: 0;
}
.ant-back-top{
  right: 50px !important;
}
h1.ant-typography, .ant-typography h1{
  font-size: 33px !important;
}
.loading-container{
  position: fixed;
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0,0,0, 0.3);
  z-index: 1009;
}
.trigger {
  font-size: 20px;
  line-height: 50px;
  padding: 0 0 0 10px;
  cursor: pointer;
  transition: color 0.3s;
  color: white;
}
.trigger:hover {
  color: #1890ff;
}
.user-container{
  float: right;
  line-height: 50px;
  height: 50px;
  background-color: transparent;
  color: white;
  border: none;
  /* max-width: 250px; */
  margin-right: 15px;
  /* white-space: nowrap;  */
  /* overflow: hidden; */
  /* text-overflow: ellipsis;  */
}
.login-container{
  float: right;
  line-height: 50px;
  height: 50px;
  background-color: transparent;
  color: white;
  border: none;
  margin-right: 15px;
  white-space: nowrap; 
  font-weight: bold;
  font-size: 18px;
}

.login-container:hover, .login-container:focus, .login-container:active, .login-container.active{
  background-color: transparent;
  color: white;
}

.user-container:hover, .user-container:focus, .user-container:active, .user-container.active{
  background-color: transparent;
  color: white;
}

.ant-advanced-search-form {
  padding: 24px;
  background: #fbfbfb;
  border: 1px solid #d9d9d9;
  border-radius: 6px;
}

.ant-advanced-search-form .ant-form-item {
  display: flex;
}

.ant-advanced-search-form .ant-form-item-control-wrapper {
  flex: 1;
}